import React from "react"
import Svg from "../ui/svg"
import StylesParser from "../ui/styles-parser"
import Title from "../ui/title"
import costanti from "../../properties/costanti"

const id_section = "Chi-siamo"
// const chi_siamo = ` #bTechfriuli#b azienda operante nel settore informatico, con all’attivo molti anni di esperienza, 
// è ubicata nel florido NordEst Italia e svolge le sue attività su tutto il territorio nazionale. 
// Si qualifica come partner referenziale non solo per le piccole medie aziende ma anche per gli studi e i piccoli professionisti. 
// Propone soluzioni tecnologiche all’#bavanguardia#b con il duplice scopo di #bmigliorare#b e rendere più efficiente l’attività dei propri partner.
// Analizza con particolare attenzione le esigenze dei clienti per fornire #bsoluzioni#b in linea con le reali richieste 
// e necessità in modo da #bcontenere i costi#b e ridurre le spese negli investimenti informatici. 
// Propone proprie soluzioni software che mantiene ed #bevolve#b sia nel campo dei servizi #bweb#b sia nel settore degli applicativi #bmobile#b. 
// Punta sulla #bqualità#b e #bprofessionalità#b del proprio personale promuovendo corsi e formazione periodici.`
const chi_siamo_arr = 
[
    ` #bTechfriuli#b azienda operante nel settore informatico, con all’attivo molti anni di esperienza, 
    è ubicata nel florido NordEst Italia e svolge le sue attività su tutto il territorio nazionale.`,
    `Si qualifica come partner referenziale non solo per le piccole medie aziende ma anche per gli studi e i piccoli professionisti. 
    Propone soluzioni tecnologiche all’#bavanguardia#b con il duplice scopo di #bmigliorare#b e rendere più efficiente l’attività dei propri partner.`,
    `Analizza con particolare attenzione le esigenze dei clienti per fornire #bsoluzioni#b in linea con le reali richieste 
    e necessità in modo da #bcontenere i costi#b e ridurre le spese negli investimenti informatici.`,
    `Propone proprie soluzioni software che mantiene ed #bevolve#b sia nel campo dei servizi #bweb#b sia nel settore degli applicativi #bmobile#b. 
    Punta sulla #bqualità#b e #bprofessionalità#b del proprio personale promuovendo corsi e formazione periodici.`,
]

/**
 * @title About Us Component
 * @param No Nessun parametro
 * @returns {React.Component} Section HTML Element
 * @description Section con la descrizione dell'azienda "Chi siamo" e le features nell'homepage.
 */

const AboutUs = () => {
    return(
        <section 
        id={id_section} title={id_section} 
        className={`group flex flex-col lg:flex-row items-center p-7 pt-0 md:p-10 bg-cover pb-[10vh] 
        overflow-hidden ` + costanti.theme.color.background.both} >
            <Svg.Programmer
            title="Chi siamo" data-sal="zoom-in" data-sal-duration="1000"
            className="portrait:w-3/4 landscape:w-2/5 md:w-2/3 lg:w-1/3 group-hover:-hue-rotate-30 
            animate-fade-in-up group-hover:animate-flip duration-500" />
        
            <div className=" lg:w-2/3 lg:pl-10 justify-center">
                {/* <h1 className="pb-8 text-7xl text-primary-500 group-hover:lg:font-medium duration-700">CHI SIAMO</h1> */}
       
                <Title text="- Chi siamo -" size="xl" bg="bg-secondary-500" classes="mt-10 group-hover:bg-emerald-500" skew center />

                {chi_siamo_arr.map((paragraph,index) =>{
                    return(
                        <p key={index} data-sal="slide-left" data-sal-duration="1000" data-sal-easing="ease-out-back"
                        className={`text-2xl text-secondary-500 dark:text-secondary-100 duration-500 ` +
                        ( index === 0  && `first-letter:uppercase first-letter:text-6xl first-letter:font-serif group-hover:first-letter:text-primary-500`)}>
                            <StylesParser tag="#b" text={paragraph} className="group-hover:text-primary-500 transition"/>
                        </p>
                    )
                })}
       
            </div>
        </section>
    )
}

export default AboutUs