import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import StylesParser from "../ui/styles-parser"
import Divider from "../ui/divider/divider"
import Svg from "../ui/svg"

const id_section = "Perché sceglierci"
const title = 'Perché sceglierci'
const title_2 = 'Diamo forma alle vostre idee'
const btn_mail = 'Contattaci'
const box_1_1 = `
Sempre al tuo fianco, per ogni necessità, con impegno e serietà
`
const box_1_2 = `
Per sentirti al sicuro e supportato con professionalità, passo dopo passo nella tua crescita digitale 
attraverso scelte misurate sulla tua realtà e i tuoi progetti futuri,
operando con il giusto equilibrio fra soluzioni professionali e impegno economico
`
const box_1_3 = `
Hai un’idea in testa? #bTechFriuli#b è il partner adatto a te. 
Possiamo mettere a disposizione le #bmigliori professionalità#b per sviluppare soluzioni basate su tecnologie #bopen source#b
`

const box_1_2_1 = `
Nel corso di più di 12 anni di attività abbiamo affrontato diverse sfide con successo sapendo stare sempre al passo con i tempi
`

/**
 * @title WhyUs Component
 * @param No Nessun parametro
 * @return {React.Component} HTML Section Element
 * @description Componente dell'homepage che ritorna un section html con le ragioni per cui scegliere
 * l'azienda e una call to action tramite il button "contattaci". 
 */

const WhyUs = () => {
    return(
        <section id={id_section} title={id_section} className="overflow-hidden">
            <div  data-sal="flip-down" data-sal-duration="400"
            className="group relative flex flex-col md:flex-row w-full h-full justify-center 
            items-center bg-cover bg-[url('/images/home-bg.png')] hover:bg-none hover:bg-gray-100 pb-40 overflow-hidden">
                <Svg.Assistant_Phone title="Perché sceglierci" className="lg:p-7 hidden lg:block md:w-full lg:w-1/3 
                content-center group-hover:animate-pulse lg:group-hover:mr-20 group-hover:-hue-rotate-30 duration-300" />
                <div className="text-center content-center lg:w-1/2 lg:group-hover:shadow-2xl lg:group-hover:bg-white
                mt-5 lg:group-hover:rounded-[4rem] duration-500 lg:group-hover:scale-105" >
                    <h1 className="pt-20 lg:pt-10 uppercase text-6xl text-white group-hover:text-primary-500">
                        {title}
                    </h1>
                    <p className="p-4 pt-10 pb-10 font-bold text-primary-400 text-2xl group-hover:text-secondary-500">
                        {box_1_1}
                    </p>
                    <p className="p-4 pb-10 text-primary-400 text-2xl group-hover:text-secondary-500">
                        {box_1_2}
                    </p>
                    <p className="p-4 pb-10 text-primary-400 text-2xl group-hover:text-secondary-500">
                        {box_1_2_1}
                    </p>
                    <div>
                        <button
                        className="bg-gray-300 text-gray-800 font-bold py-3 px-4 rounded-xl items-center 
                        animate-bounce shadow-2xl group-hover:bg-primary-700 group-hover:text-secondary-100"
                        onClick={()=>{
                            document.getElementById('contact-form').scrollIntoView({behavior: "smooth"})
                        }}>
                            <FontAwesomeIcon icon={faEnvelope} className="w-4 h-4 mr-2" />
                            <span className="uppercase font-bold">{btn_mail}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="group relative">
                <Divider className="absolute -top-32 h-32 min-w-full rotate-180 " />
                <Idea />
            </div>
        </section>
    )
}

/**
 * @title Idea Component
 * @param NoProps
 * @return {React.Component} Button dentro un fragment
 * @description Componente che ritorna un pulsante con position fixed nella window, il cui scopo è quello di tornare ad inizio pagina quando premuto. 
 * La funzione riceve in input un unico parametro "showBelow" come props, di tipo number, che definisce dopo quanti pixel deve essere visibile il pulsante in questione.
 * La visibilità viene gestita tramite la presenza o meno delle rispettive classi CSS del framework TailwindCSS.
 * L'hook useEffect viene utilizzato per aggiungere un listener allo scroll event, garantendo la presenza di "window".
 */

 const Idea = () =>{
    return(
        <div data-sal="slide-down" data-sal-duration="400"
        className="bg-secondary-600 flex flex-col md:flex-row p-10 pt-5 group-hover:bg-gray-200 duration-300
        pb-[10vh] items-center w-full h-full justify-center ">
            <span className="pt-10 md:pt-0 flex w-11/12 md:w-1/2 text-4xl text-center justify-center items-center">
                <span className="group-hover:text-primary-700 font-bold text-white group-hover:scale-110 duration-300">{title_2}</span>
            </span>
            <span className="hidden md:block text-center self-center text-6xl group-hover:text-secondary-600 animate-bounce group-hover:animate-pulse text-secondary-500 group-hover:scale-125">|</span>
            
            <p className="pt-10 md:pt-0 md:w-1/2 text-2xl self-center pl-5 group-hover:text-secondary-600 text-secondary-100 group-hover:scale-105 duration-300">
                <StylesParser text={box_1_3} tag='#b' className="group-hover:text-primary-700 text-white "/>
            </p>
        </div>
    )
}

export default WhyUs