import React from "react"

import Seo from "../components/seo"
import FirstSection from "../components/homepage/first-section"
import AboutUs from "../components/homepage/about-us"
import Features from "../components/homepage/features"
import Services from "../components/homepage/services"
import WhyUs from "../components/homepage/why-us"
import Products from "../components/homepage/products"
import Partner from "../components/homepage/partner"
import ContactForm from "../components/common/contact-form"

/**
 * @title HomePage
 * @returns {React.Component} Component Page
 * @description Home page del sito con tutte le HTML Section figlie che vanno a comporre la pagina.
 * E' presente anche il tag SEO, componente esclusivo delle pagine.
 */

const IndexPage = () => {

  return(

    <>
      <Seo title="Home" />
      
      <FirstSection />
      
      <AboutUs />
      
      <Features />
      
      <Services />
      
      <WhyUs />
      
      <Products />
      
      <ContactForm />
      
      <Partner />
    
    </>
  )  
}
  

export default IndexPage