import React from "react"
import Title from "../ui/title"
import constants from "../../properties/costanti"

/**
 * @title Partner Component
 * @param No Nessun parametro
 * @returns {React.Component} Html Section Element
 * @description Sezione con i partner aziendali dell'homepage.
 */

const Partner = () =>{

    const id_section="I-nostri-Partner"
    
    return(
        <section
        id={id_section} title={id_section} 
        className="group text-center pt-14 pb-7 duration-500 overflow-hidden bg-gradient-to-b from-gray-200 to-white dark:from-gray-700 
        dark:to-secondary-600 text-primary-500 animate-tracking-in-expand-fwd" >
            &#9620;&#9620;&#9620;&#9620;&#9620;&#9620;&#9620;&#9620;&#9620;
            {/* <h1 className="text-7xl uppercase text-primary-500 group-hover:animate-text-pop-up-top" >I Nostri Partner</h1> */}
            <Title text="I nostri Partner" bg="bg-transparent" color="text-primary-500" size="xl" center />
            
            <div className="grid grid-cols-1 md:grid-cols-3 overflow-hidden md:p-10">
                {constants.partners.map((partner, index) =>{
                    return(
                        <a href={partner.link} target="_blank"  rel="noreferrer" key={partner.title} data-sal="flip-up" data-sal-duration="500">
                            <div key={index} className="group flex justify-center items-center p-5 
                            hover:bg-gray-200 hover:shadow-lg hover:rounded-[2rem] hover:scale-105 duration-500 px-5"
                            href={partner.link} target="_blank" rel="noreferrer">
                                <img alt={partner.title} src={partner.img} className="max-w-full max-h-20 group-hover:scale-110 duration-500 p-3" />
                            </div>
                        </a>
                    )
                })}
            </div>
        </section>
    )
}
export default Partner