import React from "react"
import Title from "../ui/title"
import ProductsGrid from "../ui/grid/grid"
import costanti from "../../properties/costanti"
import {products} from "../../properties/products"

/**
 * @title Products Component
 * @param No Nessun parametro
 * @returns {React.Component} Section Html Element
 * @description Sezione che contiene la griglia dei prodotti nell'homepage.
 */


const Products = () => {
    
    const id_section = "I-nostri-Prodotti"
    
    return(
        <section id={id_section} title={id_section} className="relative w-full overflow-hidden bg-[url('/images/home-bg.png')] bg-cover pb-[10vh] mb-10 ">
            <div className="group text-center" data-sal="zoom-out" data-sal-duration="400">
                <p className="mt-10 text-white">&#9620;&#9620;&#9620;&#9620;&#9620;&#9620;&#9620;&#9620;&#9620;</p>
                <p className="text-6xl text-white mt-10 font-bold">
                    I nostri 
                </p>
                <Title text="Prodotti" size="xl" bg="bg-emerald-500" color="text-white" classes="shadow-xl" center skew/>
                <p className="mt-10 text-white">&#9620;&#9620;&#9620;&#9620;&#9620;&#9620;&#9620;&#9620;&#9620;</p>
            </div>
            <ProductsGrid list={products().list} transparentBg />
            <div className="absolute left-0 bottom-[-1px] w-full overflow-hidden h-[10vh]">
                <svg data-name="Layer 1" viewBox="0 0 1200 120" preserveAspectRatio="none" className="block h-full w-full">
                    <path d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z" className={costanti.theme.color.background.fill}></path>
                </svg>
            </div>
        </section>
    )
}

export default Products