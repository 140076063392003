import React from "react"

import costanti from "../../properties/costanti"

/**
 * @title Features Component
 * @param No Nessun parametro
 * @returns {React.Component} Div Html Element
 * @description Div con le caratteristiche dei servizi, appartiene alla "section" AboutUs dell'homepage.
 */

const Features = () => {

    let delay=200

    return (
        <div className="relative grid grid-cols-1 lg:grid-cols-3 col-auto bg-cover bg-[url('/images/home-bg-hover.png')] pb-[10vh]"
        data-sal="slide-down" data-sal-duration="1000">
            {costanti.features_list.map((feature, index) => {
                // delay = delay+100
                return(
                    // hover:bg-secondary-600 hover:text-primary-500
                    <div key={index} className="group m-4 text-white text-center mr-2 ml-2 overflow-hidden 
                        rounded-[5rem] md:rounded-[2rem] hover:shadow-xl duration-300"
                        data-sal="zoom-in" data-sal-delay={delay}>
                        <p className="text-6xl p-5 group-hover:scale-125 group-hover:animate-pulse group-hover:-hue-rotate-30 transition ">{feature.icon}</p>
                        <h1 className="text-3xl pt-8 group-hover:scale-125 uppercase transition group-hover:text-emerald-300">{feature.title}</h1>   
                        <p className="text-lg pt-10 group-hover:scale-110 text-secondary-100 transition p-10">{feature.description_short}</p>
                    </div>
                )
            })}
            <div className="absolute left-0 bottom-[-1px] w-full overflow-hidden h-[10vh]">
                <svg data-name="Layer 1" viewBox="0 0 1200 120" preserveAspectRatio="none" className="block h-full w-full">
                    <path d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z" className={costanti.theme.color.background.svg}></path>
                </svg>
            </div>
        </div>
    )
}

export default Features