import React from "react"

/**
 * @title Divider Components
 * @param {*} props 
 * @returns {React.Component} Div Html Element
 * @description Componente che renderizzata ritorna un divider a forma di triangolo rettangolo
 * se portato fino al limite dello schermo, altrimenti un trapezio rettangolo. La props fill determinerà
 * il colore di riempimento della figura.
 */

function Divider(props) {

    const fill = Boolean(props.fill) ? props.fill : " w-full fill-secondary-600 group-hover:fill-gray-200 "

    return(
        <div className={props.className}>
            <svg data-name="Divider" viewBox="0 0 1200 120" preserveAspectRatio="none" className="w-full block ">
                <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className={fill+" "}></path>
            </svg>
        </div>
    )
}

export default Divider