import React from "react"
import Svg from "../ui/svg"
import costanti from "../../properties/costanti"
import Divider from "../ui/divider/divider"

/**
 * @title First Section Component
 * @param No Nessun parametro
 * @returns {React.Component} Div HTML Element
 * @description Prima sezione dell'homepage del sito.
 */


const FirstSection = () => {
    
    const id_section = "Welcome"
    
    return(
        <div title={id_section} id={id_section} className="relative overflow-hidden w-full"
        data-sal="slide-down" data-sal-duration="800">
            <div className={"group flex flex-col w-full lg:flex-row h-[90vh] landscape:h-screen landscape:md:h-[90vh] "+
            costanti.theme.color.img.class +" bg-cover bg-no-repeat pt-[6vh] md:pt-0 justify-center "+
            "items-center overflow-hidden hover:-hue-rotate-30 duration-500"} >
                
                <Svg.Worker className="w-4/5 sm:w-2/3 md:w-5/6 portrait:p-4 portrait:md:p-14 pb-0 portrait:pt-[5vh] 
                portrait:md:mt-0 lg:pt-[5vh] lg:hidden animate-flip animation-delay-800" />
                <div className="text-center mx-auto w-4/5 sm:w-8/12 lg:w-1/2 p-5">
                    <p className="text-4xl lg:text-6xl xl:text-7xl text-white font-sans uppercase animate-tracking-in-contract-bck duration-500 animation-delay-800 ">
                        Tech<span className="text-primary-500">Friuli</span>
                    </p>
                    <p className="text-3xl lg:text-4xl xl:text-6xl text-white
                    lg:pl-3 animate-tracking-in-expand-fwd duration-700 -mt-5 mb-4 animation-delay-800">
                        Software & Servizi
                    </p>
                    <p className="text-lg md:text-xl lg:text-2xl xl:text-3xl text-white lg:pl-3 animate-tracking-in-expand-fwd duration-700 italic animation-delay-800">
                        <span className="text-primary-500">"</span>
                        Il riferimento territoriale di fiducia delle piccole e medie imprese
                        <span className="text-primary-500">"</span>
                    </p>
                </div>
                
                <Svg.Worker className="hidden lg:block lg:w-1/2 p-10 animate-flip hover:scale-110 duration-500  animation-delay-800" />

            </div>
            <Divider className="landscape:hidden landscape:md:block h-[10vh] -mt-[9vh] min-w-[100vw] rotate-180" fill={costanti.theme.color.background.fill}/>
        </div>
    )
}

export default FirstSection